import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../environments/environment";

export interface Order {
    date: string;
    description: string;
    paymentStatus: string;
    amount: number;
}

export interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
}

export interface AccountData {
    firstName: string;
    email: string;
    dateOfBirth: string;
    mailingConsent: boolean;
    dataProcessingConsent: boolean;
    availableQuestions: number;
}

export interface UpdateAccountData {
    firstName: string;
    dateOfBirth: string;
    mailingConsent: boolean;
    dataProcessingConsent: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    private baseUrl = `${environment.apiUrl}/api/account`;

    constructor(private http: HttpClient) {
    }

    getPersonalData(): Observable<AccountData> {
        return this.http.get<AccountData>(`${this.baseUrl}/personal-data`, {withCredentials: true});
    }

    getOrderHistory(): Observable<Order[]> {
        return this.http.get<Order[]>(`${this.baseUrl}/orders`, {withCredentials: true});
    }

    updatePersonalData(data: UpdateAccountData): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/personal-data`, data, {withCredentials: true});
    }

    changePassword(request: ChangePasswordRequest): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/change-password`, request, {withCredentials: true});
    }
}
